<template>
  <div class="exhibitor_list">
    <div class="ex" v-if="list && list.length">
      <div class="ex_item" @click="linkToExhibitorDetails(item.id)" v-for="(item, index) in list" :key="index">
        <div :class="['item_left',{'round': !exhibitorWebLogo}]">
          <div class="is_live" v-if="item.isLiving">
            <span></span>
            Living
          </div>
          <img v-if="item.company.logo" :src="item.company.logo" v-load="'oPic'" :class="{'round': !exhibitorWebLogo }" />
          <img v-else :src="exhibitionInfo.logo" :class="{'round': !exhibitorWebLogo }"/>
        </div>
        <div class="item_right">
          <div class="rig_top">
            <p class="ellipsis1">{{ item.company.nameEn || item.company.shortNameEn }}</p>
          </div>
          <div class="rig_2">
            <div class="ellipsis1">
              <template v-if="item.boothInfo">
                <i class="iconfont icon-dizhi"></i>
                <span class="addr" @click.stop="Totour(item)">{{ item.boothInfo}}</span>
              </template>
              <template v-if="item.categoryEn">
                <i class="iconfont icon-biaoqian1"></i>
                <span>{{ item.categoryEn }}</span>
              </template>
            </div>
            <div class="rig_3">
              <p class="issue">
                <span>{{ item.productNumber }} Product(s)</span><span class="span2">{{ item.activityNumber }} Activity(s)</span>
              </p>
              <!-- <div class="rig_top">
                <div class="continuous_ex" v-if="item.continuouNumber">
                  <div class="img_icon">
                    <img src="@/assets/img/jinpai.png" v-if="item.continuouNumber > 7" />
                    <img src="@/assets/img/yinpai.png" v-else-if="item.continuouNumber < 5" />
                    <img src="@/assets/img/tongpai.png" v-else />
                  </div>
                  <span>Participated in {{ item.continuouNumber }} consecutive exhibitions</span>
                </div>
              </div> -->
              <!-- <div class="like_count">
                <template v-for="(mode, index) in item.likes">
                  <img :src="JSON.parse(mode.userInfo).avatarUrl" v-if="JSON.parse(mode.userInfo).avatarUrl" v-load="'oPicXS'" :key="index" />
                  <img src="@/assets/img/empty.png" v-else :key="index" />
                </template>
                
              </div> -->
            </div>
            <!-- <p class="rig_4 ellipsis1" v-if="item.newestEvaluates[0]">Latest comments：{{ item.newestEvaluates[0].content }}</p> -->
          </div>
        </div>
        <div class="hot_rank" v-if="item.isPopularize">
          <span>Hot</span>
        </div>
      </div>
    </div>
    <template v-if="!isList">
        <div class="no_more" v-if="loading">
          <p>{{ $t("lang.loading") }}</p>
        </div>
        <div class="no_more" v-else-if="finished">
          <p>{{ $t("lang.Its_the_end") }}</p>
        </div>
        <div class="more" @click="moreClick" v-else-if="list && list.length">
          <p>{{ $t("lang.loadMore") }}</p>
        </div>
        <div class="no_more" v-else>{{ $t("lang.No_data") }}！</div>
    </template>
    <template v-else>
      <div class="no_more" style="width:100%" v-if="finished && !list.length">{{ $t("lang.No_data")}}！</div>
    </template>
  </div>
</template>

<script>
import { mapActions,mapGetters} from "vuex";
export default {
  name: "exhibitorList",
  props: {
    finished: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isList:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      list: [],
    };
  },
    computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      exhibitionConfig: "getExhibitionConfig",
    }),
    // 判断展商Logo是方形还是圆形
    exhibitorWebLogo () {
      // 方形为true 圆形为false
      return this.exhibitionConfig && this.exhibitionConfig.display ? this.exhibitionConfig.display.exhibitorWebLogo === "SQUARE" : false;
    },
  },
  methods: {
    ...mapActions({
      linkToExhibitorDetails: "linkTo/linkToExhibitorDetails",
      linkToTour:'linkTo/linkToTour'
    }),
    setData(arr) {
      this.list = arr;
      console.log(this.list,'adqwdqwd');
    },
    moreClick() {
      this.$emit("moreClick");
    },
    Totour(item){
      this.linkToTour()
      this.$store.commit('setExhibitor',item)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.exhibitor_list {
  width: 100%;
  .ex {
    display: flex;
    flex-wrap: wrap;
    .ex_item {
      width: 450px;
      height: 176px;
      margin: 0 20px 20px 0;
      background: #fff;
      border: solid 1px #ededed;
      padding: 20px;
      display: flex;
      position: relative;
      overflow: hidden;
      &:hover {
        @include border_color(#1890ff);
        cursor: pointer;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
      .item_left {
        width: 96px;
        height: 96px;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        border: 1px solid #e6e6e6;
        margin-right: 10px;
        img {
          display: block;
          height: 100%;
          width: 100%;
        }
        .is_live {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 62px;
          height: 20px;
          background-image: linear-gradient(90deg, #ff416c 0%, #ff4b2b 100%), linear-gradient(#000, #000);
          color: #fff;
          font-size: 12px;
          padding: 4px 6px;
          span {
            display: block;
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
          }
        }
      }
      .item_right {
        line-height: 1.3;
        max-width: calc(100% - 106px);
        overflow: hidden;
        .rig_top {
          display: flex;
          align-items: center;
          p {
            color: #333;
            font-weight: bold;
            font-size: 16px;
          }
          .continuous_ex {
            margin-bottom: 10px;
            flex-shrink: 0;
            height: 20px;
            background: rgba(#ff5e00, 0.15);
            display: flex;
            align-items: center;
            color: #ff5e00;
            font-size: 12px;
            padding: 1px;
            .img_icon {
              width: 18px;
              height: 18px;
              margin-right: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #fff;
              img {
                width: 14px;
                height: 14px;
              }
            }
            span {
              margin-right: 5px;
            }
          }
        }
        .rig_2 {
          margin-top: 6px;
          font-size: 14px;
          color: #333;
          .iconfont {
            font-size: 14px;
            margin-right: 7px;
            &.icon-dizhi {
              color: #fe781d;
            }
            &.icon-biaoqian1 {
              @include font_color(#1890ff);
            }
          }
          .addr {
            margin-right: 19px;
          }
        }
        .rig_3 {
          margin-top: 6px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .issue {
            font-size: 12px;
            color: #333;
            width: 100%;
            margin-bottom: 12px;
            span {
              background-color: #f2f2f2;
              border-radius: 4px;
              display: inline-block;
              padding: 3px 4px;
              margin-right: 10px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .like_count {
            display: flex;
            align-items: center;
            img {
              width: 21px;
              height: 21px;
              border-radius: 50%;
              border: 1px solid #fff;
              z-index: 3;
              position: relative;
              &:nth-child(2) {
                z-index: 2;
                margin-left: -10px;
              }
              &:nth-child(3) {
                z-index: 1;
                margin-left: -10px;
              }
            }
            p {
              color: #666;
              font-size: 12px;
              margin-left: 6px;
            }
          }
        }
        .rig_4 {
          margin-top: 8px;
          color: #666;
          font-size: 12px;
        }
      }
      .hot_rank {
        width: 60px;
        height: 60px;
        background: rgba(#ff5e00, 0.15);
        position: absolute;
        right: -30px;
        top: -30px;
        transform: rotate(45deg);
        span {
          position: absolute;
          bottom: 4px;
          left: 50%;
          font-size: 12px;
          color: #ff5e00;
          transform: translateX(-50%);
          line-height: 1;
          white-space: nowrap;
        }
      }
    }
  }
}
.ellipsis1{
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
white-space:normal
}

.round {
  border-radius: 50% !important;
}
</style>
