<template>
  <div class="exhibitor_wrap">
    <gSwiperCommon :bannerType="'show.page.exhibitors.pc.en'" :bannerHeight="400"></gSwiperCommon>
    <div class="exhibitor">
      <categoryRegionSelect @cateChange="cateChange" @regionChange="regionChange"></categoryRegionSelect>
      <div style="width:100%">
        <exhibitorList ref="exhibitorList" :loading="loading" :isList="true" :finished="finished" @moreClick="moreClick"></exhibitorList>
        <div class="my-page" v-if="totalCount > pageSize">
          <Page :current="pageIndex" :page-size="pageSize" :total="totalCount" @on-change="changePage" @on-page-size-change="changePageSize" show-elevator show-total />
        </div>
        <div class="u-empty" v-if="loading">{{ $t("lang.loading") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import gSwiper from "@components/gSwiper/gSwiper.vue";
import categoryRegionSelect from "@components/categoryRegionSelect/categoryRegionSelect.vue";
import exhibitorList from "@components/exhibitorList/exhibitorList.vue";
import gSwiperCommon from "@components/gSwiperCommon/gSwiperCommon.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "exhibitor",
  data() {
    return {
      dataList: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      finished: false,
      loading: true,
      options: {
        categories: [],
        regions: "",
        country: "",
        province: "",
        city: "",
        county: "",
      },
      cateArr: [],
      regionArr: [],
    };
  },
  computed: {
    ...mapGetters({
      searchType: "getSearchType",
      searchTxt: "getSearchTxt",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      exhibitorPage:'getExhibitorPage',
      cateArrVuex:'getCateArrVuex',
      regionArrNameVuex:'getRegionArrNameVuex',
    }),
  },
  components: {
    gSwiper,
    categoryRegionSelect,
    gSwiperCommon,
    exhibitorList,
  },
  watch: {
    searchTxt(val, oldVal) {
      this.$store.commit('setExhibitorPage',1)
      this.pageIndex=1;
      this.init();
    },
  },
  created() {
    if(this.exhibitorPage){
      this.pageIndex=this.exhibitorPage;
    }
    if(this.cateArrVuex&&this.cateArrVuex.length){
      this.cateArr=JSON.parse(JSON.stringify(this.cateArrVuex));
      this.cateArr.forEach((e) => {
        this.options.categories.push(e.name);
      });
    }
    if(this.regionArrNameVuex&&this.regionArrNameVuex.length){
      this.regionArr=JSON.parse(JSON.stringify(this.regionArrNameVuex)) 
      this.options.regions = this.regionArr.join(";");
    }
    this.init();
    document.title = this.exhibitionInfo ? this.exhibitionInfo.nameEn : "ExShow";
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      getShareConfig: "share/getShareConfig",
    }),
    init() {
      this.getExList();
      this.setShare();
    },
    changePage(e) {
      this.pageIndex = e;
      this.$store.commit('setExhibitorPage',e)
      this.getExList();
    },
    changePageSize(e) {
      this.pageSize = e;
    },
    async setShare() {
      let data = await this.getShareConfig({
        id: this.exhibitionId,
        name: "share.exhibition.config",
      });
      let share = {
        url: location.href,
        title: "展商列表-" + this.exhibitionInfo.name,
        desc: "",
        summary: this.exhibitionInfo.remark,
        pics: this.exhibitionInfo.logo,
      };
      if (data) {
        let dataJson = JSON.parse(data);
        if (dataJson.content) {
          share.title = dataJson.content;
        }
        if (dataJson.des) {
          share.desc = dataJson.des;
          share.summary = dataJson.des;
        }
      }
      this.$store.commit("setShare", share);
    },
    //类别改变
    cateChange(arr) {
      this.cateArr = arr;
      this.conditionChange();
    },
    conditionChange() {
      this.options = {
        categories: [],
        regions: "",
        country: "",
        province: "",
        city: "",
        county: "",
      };
      if (this.cateArr.length) {
        this.cateArr.forEach((e) => {
          this.options.categories.push(e.name);
        });
      }
      if (this.regionArr.length) {
        this.options.regions = this.regionArr.join(";");
      }
      this.pageIndex = 1;
      this.$store.commit('setExhibitorPage',1)
      this.totalCount=0;
      this.finished = false;
      this.getExList();
    },
    //地区改变
    regionChange(arr) {
      this.regionArr = arr;
      this.$store.commit('setRegionArrNameVuex',arr)
      this.conditionChange();
    },
    //获取数据
    async getExList() {
      this.loading = true;
      let query = `
        query($query:QueryExhibitorInput!){
          exhibitorQuery{
            queryCustom(query:$query){
                hasNextPage
                pageIndex
                totalCount
                
               
                items {
                  activityNumber
                activities(num:3){
                    id
                    title
                  }
                  banner
                  boothArea
                  boothInfo
                  businesses(num:3){
                    id
                    remark
                    isSample
                  }
                  category
                  categoryEn
                  company{
                    id
                    logo
                    name
                    nameEn
                    shortName
                    shortNameEn
                    category
                    categoryEn
                    region
                    address
                    countryEn
                    provinceEn
                    cityEn
                  }
                  compId
                  exhibitionId
                  followCount
                  id
                  isPopularize
                  isLiving
                  likeCount
                  lookCount
                  proCount
                  productNumber
                  activityCount
                  liveCount
                  continuouNumber
                  likes{
                    userInfo
                  }
                  newestEvaluates{
                    id
                    content
                  }
                  products(num:10){
                    id
                    exhibitorId
                    banner
                    view3DUrl
                  }
                  shareCount
                  talkCount
                  thinkCount
                  userCount
                  viewCount
                }
              }
            }
        }
      `;
      let opt = {
        query: query,
        variables: {
          query: {
            compName: this.searchType === "1" ? this.searchTxt : "",
            exhibitionId: this.exhibitionId,
            page: this.pageIndex,
            size: this.pageSize,
            ...this.options,
          },
        },
      };
      let res = await this.graphqlPost(opt);
      let data = JSON.parse(JSON.stringify(res.data.exhibitorQuery));
      if (data && data.queryCustom) {
        let currentList = data.queryCustom.items;
        currentList.map((c) => {
          if (c.categoryEn && c.categoryEn.indexOf("[") !== -1) {
            if (JSON.parse(c.categoryEn)[0]) {
              c.categoryEn = JSON.parse(c.categoryEn)[0].Value;
            } else {
              c.categoryEn = "";
            }
          }
          if (c.company) {
            c.regionTxt = `${c.company.countryEn ? c.company.countryEn + "." : ""}${c.company.provinceEn ? c.company.provinceEn : ""}${c.company.cityEn ? "." + c.company.cityEn : ""}`;
          }
        });
        this.dataList = currentList;
        this.loading = false;
        if (!data.queryCustom.hasNextPage) {
          this.finished = true;
        }
      }
      this.totalCount=data.queryCustom.totalCount;
      if (this.$refs.exhibitorList) {
        console.log("this.dataList", this.dataList);
        this.$refs.exhibitorList.setData(this.dataList);
      }
    },
    //点击加载更多
    moreClick() {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getExList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.exhibitor_wrap {
  background: #f7f7f7;
  .exhibitor {
    width: 1200px;
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;
    .my-page{
      width: 100%;
      flex-shrink: 0;
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
